<template>
  <v-container fluid>
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-container
        id="regular-tables"
        fluid
        tag="section"
      >
        <v-data-table
          :headers="_headers"
          :items="chargeSessionList"
          :loading="isLoading"
          :items-per-page="20"
          :server-items-length="meta.totalItems"
          :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
          :options.sync="options"
          class="elevation-0 scrollable"
        >
          <!-- Data Table Scroll Horizontal -->
          <template v-slot:[`footer`]>
            <div
              v-if="chargeSessionList.length > 0"
              class="footer-scroll-btn"
            >
              <data-table-scroll />
            </div>
          </template>
          <!-- Data Table Scroll Horizontal -->
          <!-- actions -->

          <!-- actions -->
          <template v-slot:[`item.start_soc`]="{ item }">
            <div>{{ item.start_soc }} %</div>
          </template>
          <template v-slot:[`item.end_soc`]="{ item }">
            <div>{{ item.end_soc }} %</div>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            <div>{{ item.duration }}</div>
          </template>
          <template v-slot:[`item.charge_gain`]="{ item }">
            <div>{{ item.charge_gain }} %</div>
          </template>
          <template v-slot:[`item.energy_consumption`]="{ item }">
            <div>{{ item.energy_consumption }}</div>
          </template>

          <template v-slot:no-data>
            {{ $t("no_data_available") }}
          </template>
          <template v-slot:top>
            <div class="d-flex">
              <v-row class="ml-2 mx-0">
                <date-filter
                  v-model="options.date"
                  cols="3"
                />
              </v-row>
              <div>
                <refresh-button
                  :is-loading="isLoading"
                  @reload="fetchData"
                />
              </div>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  components: {
    DateFilter: () => import("@/components/base/DateFilter.vue"),
    refreshButton: () =>
      import("../../../../components/base/RefreshButton.vue"),
    dataTableScroll: () => import("@/components/base/dataTableScroll.vue"),
  },

  data() {
    return {
      isDatePicker: false,
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      meta: "vehicleChargeSession/getMeta",
      chargeSessionList: "vehicleChargeSession/getChargeSessionList",
      isLoading: "vehicleChargeSession/getIsLoading",
      vehicle: "vehicles/getVehicle",
    }),
    _headers() {
      const head = [
        // bms_id column will show only for ecodost
        // ...(this.vehicle?.vehicle_model_type == "three_wheeler"
        //   ? [
        //       {
        //         text: this.$t("bms_id"),
        //         align: "center",
        //         sortable: true,
        //         value: "bms_id",
        //       },
        //     ]
        //   : []),

        {
          text: this.$t("session_id"),
          align: "center",
          sortable: true,
          value: "interval_id",
        },
        {
          text: this.$t("start_datetime"),
          align: "center",
          sortable: false,
          value: "start_datetime",
        },
        {
          text: this.$t("end_datetime"),
          align: "center",
          sortable: false,
          value: "end_datetime",
        },
        {
          text: this.$t("start_soc"),
          align: "center",
          sortable: false,
          value: "start_soc",
        },
        {
          text: this.$t("end_soc"),
          align: "center",
          sortable: false,
          value: "end_soc",
        },
        {
          text: this.$t("duration"),
          align: "center",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("charge_gain"),
          align: "center",
          sortable: false,
          value: "charge_gain",
        },
        {
          text: this.$t("energy_gain"),
          align: "center",
          sortable: false,
          value: "energy_consumption",
        },
        {
          text: this.$t("monetary_cost"),
          align: "center",
          sortable: false,
          value: "monetary_cost",
        },
        {
          text: this.$t("electricity_per_unit_price"),
          align: "center",
          sortable: false,
          value: "electricity_per_unit_price",
        },
      ];
      if (this.vehicle.battery_slot_type == "swappable") {
        head.unshift({
          text: this.$t("bms_id"),
          align: "center",
          sortable: true,
          value: "bms_id",
        });
      }
      return head;
    },
  },
  watch: {
    options: {
      handler: debounce(async function () {
        const imei = this.$route.params.imei;
        this.options.imei = imei;
        await this.$store
          .dispatch("vehicleChargeSession/list", this.options)
          .then(() => {});
      }, 1000),
      deep: true,
    },
  },

  beforeDestroy() {
    this.$store.commit("vehicleChargeSession/SET_VEHICEL_CHARGE_SESSION", []);
  },

  methods: {
    async fetchData() {
      const imei = this.$route.params.imei;
      this.options.imei = imei;
      await this.$store
        .dispatch("vehicleChargeSession/list", this.options)
        .then(() => {});
    },
  },
};
</script>
